// @ts-strict-ignore
import { Chart, Element } from "chart.js";

import type {
    VectorActivityTimelineAlarm,
    ActivityTimelineAlarm,
    ActivityTimelineService,
} from "components/ActivityTimeline/ActivityTimeline.types";
import { dayjs, Dayjs } from "components/ui/DatePicker/date.utils";
import { dateFormat } from "utils/date.utils";

type ElementWithContext = Element & {
    $context: {
        raw: {
            value: string;
        };
    };
};

type ChartArgs = { meta: { data: Element[] } };

const drawTextInPoint = (chart: Chart, element: ElementWithContext) => {
    const value = element.$context?.raw?.value;
    if (!value) return;

    const ctx = chart.ctx;
    const { radius } = element.options;
    const fontSize = parseInt(radius) * 0.6;
    ctx.save();
    ctx.font = `${fontSize}px Ubuntu`;
    ctx.fillStyle = "white";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillText(value, element.x, element.y + radius / 15);
    ctx.restore();
};

export const drawValueInPointPlugin = {
    id: "drawValueInPointPlugin",
    afterDatasetDraw(chart: Chart, args: ChartArgs) {
        const elements = args.meta.data ?? [];
        elements.forEach((element: Element) => drawTextInPoint(chart, element as ElementWithContext));
    },
};

export type IntervalRange = "day" | "week" | "month";

export const getIntervalRange = (firstDate: Dayjs, lastDate: Dayjs): IntervalRange => {
    const dayStepBoundary = 31;
    const monthStepBoundary = 90;
    const daysBetween = lastDate.diff(firstDate, "days");
    if (daysBetween < dayStepBoundary) return "day";
    else if (daysBetween < monthStepBoundary) return "week";
    else return "month";
};

export const fillTimeUnitsBetweenDates = (startDate: Date, endDate: Date, intervalRange: IntervalRange) => {
    const parsedStartDate = dayjs(startDate);
    const parsedEndDate = dayjs(endDate);
    const intervalStart = parsedStartDate.endOf(intervalRange);
    const intervalEnd = parsedEndDate.endOf(intervalRange);

    const dates: string[] = [];

    for (let date = intervalStart; date <= intervalEnd; date = date.add(1, intervalRange).endOf(intervalRange)) {
        dates.push(date.format(dateFormat));
    }

    return dates;
};

export const enhanceDataWithInterval = <T>(data: T[], intervalRange: IntervalRange): T[] => {
    if (data.length === 0) return [];
    const dataWithInterval = data.map((item) => ({
        ...item,
        interval: dayjs(item["date"]).endOf(intervalRange).format(dateFormat),
    }));
    return dataWithInterval;
};

export const countOccurenceCountByDescription = (
    data: (ActivityTimelineAlarm | VectorActivityTimelineAlarm)[]
): ActivityTimelineAlarm[] => {
    const mappedData: ActivityTimelineAlarm[] = [];
    Object.values(Object.groupBy(data, (item) => item.description)).forEach((arrayByDescription) => {
        const groupedByInterval = Object.groupBy(arrayByDescription, (item) => item["interval"]);
        Object.values(groupedByInterval).forEach((arrayByInterval) => {
            mappedData.push({
                ...(arrayByInterval[0] as any),
                occurrencesCount: arrayByInterval.length,
            });
        });
    });
    return mappedData;
};

export const countServiceTypeByInterval = (data: ActivityTimelineService[]): ActivityTimelineService[] => {
    const mappedData: ActivityTimelineService[] = [];
    Object.values(Object.groupBy(data, (item) => item.interval)).forEach((arrayByInterval) => {
        const newObject: ActivityTimelineService = {
            ...arrayByInterval[0],
            repairs: 0,
            pm: 0,
            other: 0,
        };
        arrayByInterval.forEach((item) => {
            newObject.repairs += item.label === "Repairs" ? item.hours : 0;
            newObject.pm += item.label === "PM" ? item.hours : 0;
            newObject.other += item.label === "Other" ? item.hours : 0;
        });
        mappedData.push(newObject);
    });
    return mappedData;
};

export const getDateLabelByInterval = (date: string | number, intervalRange: IntervalRange): string => {
    const parsedDate = dayjs(date);
    switch (intervalRange) {
        case "day":
            return parsedDate.format("D MMM");
        case "week":
            return `${parsedDate.startOf("week").format("D MMM")} - ${parsedDate.endOf("week").format("D MMM")}`;
        case "month":
            return parsedDate.format("MMM");
    }
};
